body,label,input,button {
    color: #000000;
    font-family: 'Imprima' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
input, textarea, button, .btn, select {
    border-radius: 0px !important;
}
h1,h2 {
    font-weight: 500;
    color: #747474;
    font-size: 30px;
}
nav {
    border-bottom: 1px solid #ced4da; 
}
a, a:hover,.btn-link, span.nav-link {
    color: #0475B0;
    cursor: pointer;
}
a:hover,.btn-link:hover, span.nav-link:hover {
    color: #91a8bf;
    text-decoration: none;
}
span.nav-link.active {
    text-decoration: underline;
}
