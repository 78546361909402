/* .ant-col{
    min-width: 214px;
}*/
.items-inquery .card-item .ant-card-head{
    position: absolute;
    width: 100%;
    border-bottom: none;
} 
.items-inquery .card-item .ant-card-head svg{
    margin-top: -25px;
    margin-right: -15px;
} 
/* .ant-card-body{
    padding: 10px!important;
} */
/* .card-item{
    width:174px;
} */
.items-inquery .card-item .ant-card-meta-title{
    font-size: 14px;
    color: rgb(122, 122, 122);
    margin-bottom: 0!important;
}
.items-inquery .card-item .ant-card-meta-description{
    font-size: 16px;
    color: rgb(29, 29, 29);
}
.items-inquery .card-item .ant-card-body{
    padding: 10px;
}

.items-inquery .card-item .ant-card-extra svg{
    background-color: white;
    border: 1px solid rgb(22, 22, 22);
    border-radius: 3px;
}
.drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container:hover {
    background: #eee;
    border-color: #353535;
  }
  
  .drop-container:hover .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }


  @media (max-width: 370px) {
    .drop-title {
        font-size: 14px;
    }
  }

 .card-items .card-items__categorie{
    justify-content: center;
 } 

 .card-items .card-items__categorie button:hover{
    /* border: 1px solid red; */
    /* background-color: red; */
    border-bottom: none!important;

 }

.items-inquery .card-item .ant-card-cover img{
    min-height: 130px;
    max-height: 130px;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1400px;
    }
}

@media (max-width: 1200px) and (min-width: 992px){
    .container, .container-lg, .container-md, .container-sm {
        max-width: 1192px;
    }
}

@media (max-width: 992px) and (min-width: 768px){
    .container, .container-lg, .container-md, .container-sm {
        max-width: 1192px;
    }
}

@media (max-width: 768px) and (min-width: 576px){
    .container, .container-md, .container-sm {
        max-width: 968px;
    }
}

@media (max-width: 576px){
    .container, .container-sm {
        max-width: 576px;
    }    
}