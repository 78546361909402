.rigthInput{
    width: 50%;
}

.leftInput{
    width: 50%;
}

.drop-container-image {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20px;  
    padding: 20px;
    border-radius: 5px;
    border: 0.5px solid #e1e1e1;
    color: #4b4b4b;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container-image:hover {
    background: #eee;
    border-color: #9b9b9b;
  }
  
  .drop-container-image:hover .drop-title {
    color: #4b4b4b;
  }
  
  .drop-title-image {
    color: #4b4b4b;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }

  .drop-container-image{
    margin-bottom: 0;
    width: 20px;
  }

  @media (max-width: 900px) {
    .rigthInput{
        width: 100%;
    }
    
    .leftInput{
        width: 100%;
    }

    .rigthInput .ant-form-item-row .ant-form-item-control{
        margin-left: 0;
        text-align: end;
    }
  }

  .table-variants td{
    padding: 0!important;
    min-height: 35px;
    text-align: center;
  }
  /* .table-variants th:nth-child(2){
    text-align: left;
    padding-left: 15px!important;
  } */
  .table-variants td>input{
    border: none;
    min-height: 45px;
  }

  /* .table-variants td:first-child{
    padding-top: 12px!important;
  } */

  /* .table-variants td:first-child {
    padding-top: 12px!important;
  } */

  /* .table-variants td:first-child .ant-checkbox-wrapper{
    margin-right: 0!important;
  } */

  .footer-addItem{
    margin-top: 40px;
  }

  .ant-card-body .table-variants .champ-image{
    padding: 10px!important;
  }


  .drop-container-image-default {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;  
    padding: 20px;
    border-radius: 5px;
    border: 0.5px solid #e1e1e1;
    color: #4b4b4b;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container-image-default:hover {
    background: #eee;
    border-color: #9b9b9b;
  }
  
  .drop-container-image-default:hover .drop-title {
    color: #4b4b4b;
  }
  
  .drop-title-image {
    color: #4b4b4b;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }
  .drop-container-image-default{
    margin-bottom: 0;
    width: 60px;
  }

  

  @media (min-width: 750px) {
    .card-add__item-description .ant-form-item-control{
      max-width: 93%!important;
    }

    .card-add__item .basic-infos .perso .ant-input{
      width: 140%;
    }
    .card-add__item .basic-infos .ant-select-in-form-item{
      width: 140%!important;
    }
  
    /* .card-add__item .card-add__item-description{
      width: 140%;
    } */
  }

  .variants-lists{
    display: flex!important;
    flex-wrap: wrap;
    justify-content: center;
  }

  .variants-miniature,.variants-miniature__mark{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    /* cursor: pointer; */
    padding-top: 5px;
    width: 100px;
    margin: 5px;
  }

  .variants-miniature:hover{
    transition: all 1s;
    box-shadow: #8b8b8b;
    background-color: #e9e9e9;
  }

  .variants-miniature.active{
    box-shadow: #8b8b8b;
    background-color: #e9e9e9;
  }

  .tableau-mark{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .variants-miniature label,.variants-miniature__mark label{
    /* font-weight: bold; */
    font-size: 10px!important;
    margin-top: 5px;
    text-align: center;
  }
  .variants-miniature__item{
    margin-top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    border: 2px solid white;
    /* background-color: red; */
  } 

  .selected-color{
    margin: 0;
    border-bottom: 2px solid;
  }

  @media (max-width: 770px) {
    .variants-miniature__mark{
      display: none;
    }
  }

.week-input{
  padding: 0!important;
}

.week-input__restock{
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}