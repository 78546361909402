// .detail-product .ant-modal-header,.detail-product .ant-modal-footer{
//     border: none;
// }

// .detail-product .ant-modal-header .ant-modal-title{
//     margin-top: 5px;
//     font-weight: bold;
// }


// .item-image__title{
//     margin-top: 10px;
//     margin-bottom: 0px;
//     font-size: 18px;
// }
// @media (max-width: 800px) {
//     .item-image__couleur{
//         width: 100%;
//     }
// }
// @media (max-width: 950px) {
//     .item-image__secondary{
//         display: none;
//     }
// }

// @media (max-width: 703px) {
//     .item-title{
//         margin-top: 20px;
//     }
//     .item-image__secondary{
//         display: block;
//     }
// }

// @media (max-width: 370px) {
//     .item-image__secondary{
//         display: none;
//     }
// }

// .item-image__couleur img{
//     margin-right: 10px;
//     cursor: pointer;
// }

// .item-title{
//     font-size: 16px;
//     margin-bottom: 0;
//     font-weight: bold;
// }

// .item-footer button{
//     margin-right: 10px;
//     background-color: rgb(211, 211, 211);
//     border-radius: 25px!important;  
// }

// .detail-product .ant-tabs-content-holder{
//     background-color: rgb(236, 236, 236);
//     min-height: 285px;
//     padding: 5px;
// }

// .detail-product .ant-tabs-nav{
//     margin-bottom: 0!important;
// }

// .detail-product .item-image__primary{
//     min-height: 250px;
//     max-height: 250px;
//     border: 0.1px solid rgb(226, 226, 226);
// }

// .detail-product .item-image__secondary{
//     border: 0.1px solid rgb(226, 226, 226);
// }


// .detail-product .item-image__swatch{
//     min-height: 100px;
//     max-height: 100px;
//     border: 0.1px solid rgb(226, 226, 226);
// }



/* Manomboka eto */
.detail-modal{
    .item-footer button{
        margin-right: 10px;
        // background-color: rgb(211, 211, 211);
        border-radius: 25px!important;  
    }
    
    .detail-items__image{
        justify-content: center;
    }
    
    .detail-items__name{
        font-size: 25px;
        margin-bottom: 0;
    }
    
    .detail-items__title{
        font-size: 14px;
        margin-bottom: 0;
        margin-left: 2px;
    }
    
    .detail-items__price{
        font-size: 12px;
        font-weight: bold;
        margin-left: 2px;
    }
    
    .detail-items__swatch{
        margin-top: 10px;
    }
    
    .detail-items__swatch .slick-cloned{
        display: none!important;
    }
    
    .detail-items__swatch .slick-slide.slick-active{
        width: 80px!important;
    }
    
    .detail-items__image{
        justify-content: center;
    }
    
    .item-image__swatch{
        margin-bottom: 5px;
        border: 1px solid rgb(180, 180, 180);
    }

    .item-image__couleur .ant-image{
        margin-right:10px;
    }
    
    .item-image__couleur img{
        margin-right: 10px;
        cursor: pointer;
    }

    .ant-tabs-nav-list{
        width: 100%;
    }
    
    .ant-tabs-tab {
        width: 29.3%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ant-tabs-nav{
        margin-bottom: 0!important;
    }
    
    .ant-tabs-content-holder{
        min-height: 90px!important;
        padding: 10px;
        background-color: rgb(231, 231, 231);
    }
    @media (max-width: 820px) {
        .detail-items__swatch{
            display: flex!important;
            width: 100%!important;
        }
    }
    
    .ant-tabs-tab{
        border-bottom: 2px solid rgb(192, 192, 192)!important;
    }
    
    .table{
        text-align: center;
        margin-bottom: 0;
    }
    
    th{
        background-color: white;
        font-weight: 500;
        font-size: 10px;
        padding: 0.3rem!important;
        // border-bottom: none;
    }
    
    td{
       font-size: 11px;
       font-weight: 500;
       padding: 0.3rem!important;
       border-left: 1px solid rgb(153, 153, 153);
    //    border-bottom: 2px solid rgb(231, 231, 231);
       width: 50px;
    }
    
    .table .title{
        width: 120px;
    }

    .table input{
        // width: 65%;
        border: 1px solid rgb(211, 211, 211);
    }

    .table .commande-input {
        padding: 0!important;
    }
    .stock-badge{
        background-color: aquamarine;
        padding: 6px;
        width: 100%;
    }
    
    .detail-items__swatch{
        width: 600px;
    }
    
    .detail-items__swatch-slide{
        margin: 5px!important;
    }
    
    .slick-list{
        padding: 0 0!important;
    }
    
    .slick-slide{
        width: 100px!important;
    }
    ::-webkit-scrollbar {
        height: 10px;              
        width: 4px;          
        border: 1px solid #d5d5d5;
    }

    ::-webkit-scrollbar {
        width: 12px;
    }
      
      
    /* line 17, sass/page/_home.scss */
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
      
      
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgba(199, 199, 199, 0.8);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
      
      
    /* line 30, sass/page/_home.scss */
    
    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 38, 255, 0.4);
    }
      
    ::-webkit-scrollbar-button {
        width: 100px;
    }
    
      
    .item-image__couleur{
        display: flex;
        overflow-x: auto;
    }    

    .ant-modal-footer{
        display: none;
    }
    
    .color-active{
        transition: all 5ms;
        border: 2px solid #1b91ff;
    }

    .input-commande{
        // width: 68%;
        width: 44px;
    }
}

.stock-insuffisant .ant-modal-footer{
    display: none;
}

.ant-modal-confirm-btns{
    text-align: center!important;
}
