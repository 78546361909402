.commande-list{
    .dot{
        display: flex;
        justify-content: center;
    }
    .dot-item{
        width: 15px;
        height: 15px;
        border-radius: 100%;
        margin-right: 5px;
        margin-top: 5px;
    }
}