.commande-edit{
    p{
        margin-bottom: 10px;
    }
    .title-label{
        font-weight: bold;
    }
    .footer-table{
        text-align: right;
    }
    .ant-table-cell{
        padding: 5px!important;
    }
}