section.main {
    margin-top: 5rem;
}

p.msg_ko {
    color: #f36b21;
}

.form-control {
    border-radius: 0px;
}

div.react-phone-number-input__icon{
    border: none;
}
imput.react-phone-number-input__input{
    border-bottom: none;
}